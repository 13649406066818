import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react';
import PaymentPage from './PaymentPage';
import { ProductType } from '../../constants/enum';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import { faCircleCheck, faClose, faCross, faMultiply, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import RoutesConstants from '../../shared-components/Routes/routes.constants';
import { useStore } from '../../contexts/StoreProvider';

const PaymentConfirmation = () => {
    const [searchParams] = useSearchParams();
    const [transactionId, setTransactionId] = useState<string|null>(searchParams.get('id'));
    const [status, setStatus] = useState<string|null>(searchParams.get('status'));
    return <>
        <div className="banner--light breadcrumb-area">
            <div className="container">
            
            {status == "success" ? 
                <>
                    <FontAwesomeIcon icon={faCircleCheck} name="linkedIn " className='display-1' style={{color:"var(--green)"}}/>
                
                    <h1 className='mt-4'><FormattedMessage id='payment_success' /></h1>
                    <h4 className='my-2 py-2 text-secondary'> <FormattedMessage id ='transaction_id'/> : {transactionId}</h4>
                        <div className='btn-group'>
                            <button className='btn btn-primary p-3' onClick={() => window.location.href = `${RoutesConstants.Dashboard}`}>
                                <FormattedMessage id='Go to Dashboard' />
                            </button>
                        </div>
                </>
                :
                <>
                    <FontAwesomeIcon icon={faTimesCircle} name="linkedIn " className='display-1' style={{color:"var(--red)"}}/>
                
                    <h1 className='mt-4'><FormattedMessage id='payment_failure' /></h1>
                    <h4 className='my-2 py-2 text-secondary'> <FormattedMessage id ='transaction_id'/> : {transactionId}</h4>
                    <div className='btn-group'>
                        <button className='btn btn-primary p-3' onClick={() => window.location.href = `${RoutesConstants.Dashboard}`}>
                            <FormattedMessage id='Go to Dashboard' />
                        </button>
                    </div>
                </>
            }
            </div>
        </div>
    </>
}
export default observer(PaymentConfirmation);