/*    
<summary>
   This class component is all about Managing Authentication functionality.
   Developer: Aashish Singh, Created Date: 08-Mar-2023
</summary>
<param>No Parameter Passed</param>
<returns>Returns class instance</returns>
*/
import base64 from 'base-64';
import { action, computed, makeObservable, observable, toJS } from 'mobx';
import URLConstants from '../../constants/url.constants';
import * as baseService from '../service/base-service';
import jwt from 'jwt-decode';
import ILogin from '../../models/ILogin';
import { IAuthState } from '../../models/state/IAuthState';
import IApiResponse, { IApiSuccessResponse } from '../../models/response/IApiResponse';
import IAuthResponse from '../../models/response/IAuthResponse';
import { ICommonState } from '../../models/state/ICommonState';
import toast, { Toaster } from "react-hot-toast";
import { formatMessage } from '../../translations/format-message';
import { errorMessage } from '../../constants/error.constants';
import RoutesConstants from '../../shared-components/Routes/routes.constants';
import IContactUsingEmail from '../../models/IContactUsingEmail';
import ITrainerContactUsInfo from '../../models/ITrainerConatactUsInfo';
import { IAddUser } from '../../models/Form/IAddUser';
import { ProductType, SendFriendRequestEnum, UserType } from '../../constants/enum';
import { initialState as updateUserInitialState } from '../initial-state/update-user-state';
import { IUpdateUser } from '../../models/Form/IUpdateUser';
import { IUser } from '../../models/IUser';
import { IGetAllTest } from '../../models/response/IAdminResponse';
import { initialState as GetAllTestInitialState } from '../initial-state/get-all-test-state'
import IChangePassword from '../../models/Form/IChangePassword';
import IAddSendFriendRequest from '../../models/Form/IAddSendFriendRequest';
import { initialSendFriendRequestState } from '../initial-state/get-all-sendFriendRequest';
import IGetSendFriendRequest, { } from '../../models/Form/IGetSentFriendRequest';
import { ITestInfo } from '../../models/ITestInfo';
import IResetPassword from '../../models/Form/IResetPassword';
import axios from 'axios';
import { cardActionAreaClasses } from '@mui/material';
import IPaymentCard from '../../models/Form/IPaymentCard';
import { IPagingDetails } from '../../models/response/IPagingDetails';
import { initialPaginationStateValue } from "../../constants/options.constant";
import { IPaymentSettingInfo } from '../../models/response/IPaymentSettingValue';
import { SettingsInfoState as initialPaymentSettingInfo } from '../initial-state/get-PaymentSettingsInfo-state';
import { IPayment } from '../../models/IPayment';

export type InitialStateType = {
    success: boolean;
    error: string;
    inProgress: boolean;
}

export interface IPaymentStatus {
    transactionId: string;
    status: string;
}

export interface IPaymentErrorDetail {
    field: string;
    message: string;
}

export interface IPaymentResponse {
    code: number;
    message: string;
    details: IPaymentErrorDetail[];
}


export class AuthStore implements IAuthState, ICommonState {
    inProgress = false;
    error = '';
    isAuthenticated = false;
    isAgent = false;
    token = '';
    userId: number = 0;
    userType = '';
    email = '';
    lastAccountAccess = '';
    Referer:any

    initialStateValue: InitialStateType = {
        success: false,
        error: '',
        inProgress: false
    }

    initialPaymentStateValue = {
        success: false,
        error: '',
        inProgress: false,
        status: '',
        threeDSHTML: '',
        threeDSRedirectURL: ''
    };

    loginFormData: ILogin = {
        email: "",
        password: "",
        rememberMe: false,
    }
    
    couponCodeDiscount: number = -1;
    testPriceInfo: IPaymentSettingInfo = initialPaymentSettingInfo
    userDetail: IUpdateUser = updateUserInitialState
    getSendFriendRequestAll = initialSendFriendRequestState
    getSendFriendRequestSend = initialSendFriendRequestState
    getSendFriendRequestReceived = initialSendFriendRequestState
    addUpdateUserState = { ...this.initialStateValue }
    otpGenerationState = { ...this.initialStateValue}
    otpVerificationState = { ...this.initialStateValue }
    contactUsingMailState = { ...this.initialStateValue }
    loginUserState = { ...this.initialStateValue }
    changePasswordState = { ...this.initialStateValue }
    forgotPasswordState = { ...this.initialStateValue }
    verifyResetPasswordTokenState = {...this.initialStateValue}
    resetPasswordState = { ...this.initialStateValue }
    testPaginationState = {...initialPaginationStateValue}
    logoutSuccess = false;
    logoutError = "";
    logoutInprogress = false;
    trainerContactUsInfoSuccess = false;
    trainerContactUsInfoError = "";
    testDataList: IGetAllTest = GetAllTestInitialState
    getAllUserTestState = {...this.initialStateValue};
    deleteTestState = { ...this.initialStateValue }
    addSendFriendRequestState = { ...this.initialStateValue }
    getSendFriendRequestState = { ...this.initialStateValue }
    getSendFriendRequestStateSend = { ...this.initialStateValue }
    getSendFriendRequestStateReceived = { ...this.initialStateValue }
    UpdateStatusState = { ...this.initialStateValue }
    DeleteRequestState = { ...this.initialStateValue }
    CancelRequestState= { ...this.initialStateValue }
    DeleteFriendsState= { ...this.initialStateValue }
    paymentState = { ...this.initialStateValue }
    validateRefererState = {...this.initialStateValue}
    finalPaymentState = { ...this.initialPaymentStateValue };
    couponCodeDiscountState = { ...this.initialStateValue};
    getTestPriceState = {...this.initialStateValue}

    constructor() {
        makeObservable(this, {
            inProgress: observable,
            error: observable,
            isAuthenticated: observable,
            isAgent: observable,
            userType: observable,
            lastAccountAccess: observable,
            loginFormData: observable,
            logoutSuccess: observable,
            logoutError: observable,
            logoutInprogress: observable,
            userDetail: observable,
            loginUserState: observable,
            changePasswordState: observable,
            forgotPasswordState: observable,
            verifyResetPasswordTokenState: observable,
            resetResetPasswordState: observable,
            resetPasswordState: observable,
            contactUsingMailState: observable,
            trainerContactUsInfoError: observable,
            trainerContactUsInfoSuccess: observable,
            addUpdateUserState: observable,
            otpGenerationState: observable,
            otpVerificationState: observable,
            DeleteRequestState: observable,
            CancelRequestState:observable,
            DeleteFriendsState:observable,
            UpdateStatusState: observable,
            testDataList: observable,
            getAllUserTestState: observable,
            deleteTestState: observable,
            addSendFriendRequestState: observable,
            getSendFriendRequestState: observable,
            getSendFriendRequestStateSend: observable,
            getSendFriendRequestStateReceived: observable,
            getSendFriendRequestAll: observable,
            getSendFriendRequestSend: observable,
            getSendFriendRequestReceived: observable,
            couponCodeDiscount: observable,
            testPriceInfo: observable,
            getTestPriceState: observable,
            paymentState: observable,
            validateRefererState:observable,
            couponCodeDiscountState: observable,
            testPaginationState:observable,
            reset: action,
            login: action,
            logout: action,
            otpGenerationOnRegister: action,
            otpGenerationOnLogin: action,
            resetOtpGenerationState: action,
            otpVerification: action,
            authenticateUser: action,
            resetOtpVerificationState: action,
            resetLoginUser: action,
            getUserDetail: action,
            contactUsResponse: action,
            trainerContactUs: action,
            forgotPassword: action,
            verifyResetPasswordToken: action,
            resetPassword: action,
            resetForgotPasswordState: action,
            resetVerifyResetPasswordTokenState: action,
            changePassword: action,
            resetChangePasswordState: action,
            resetDiscountState: action,
            resetPaymentState: action,
            getAllTest: action,
            deleteTest: action,
            resetDeleteTest: action,
            postSendFriendRequest: action,
            getAllFriendRequests: action,
            getSendFriendRequestsSend: action,
            getSendFriendRequestsReceived: action,
            UpdateStatus: action,
            DeleteRequest: action,
            DeleteFriends:action,
            CancelRequest:action,
            personalPlanPayment: action,
            getCouponCodeDiscount: action,
            getTestPrice: action,
            getToken: computed,
            getUserId: computed,
            getIsAgent: computed,
            getUserDetailComputed: computed,
            getLastAccountAccess: computed,
            allTest: computed,
            allSendFriendRequest: computed,
            allSendFriendRequestSend: computed,
            allSendFriendRequestReceived: computed,
            finalPaymentState: observable,
            resetFinalPaymentState: action,
            resetCouponCodeDiscountState: action,
            resetGetAllUserTestState: action,
        });
    }

    get getUserType() {
        return localStorage.getItem('userType');
    }

    get getToken() {
        return localStorage.getItem('token');
    }

    get getIsAgent() {
        return localStorage.getItem('isAgent') === 'true';
    }

    get getRefreshToken() {
        return localStorage.getItem('refreshToken');
    }

    get getEmail() {
        return localStorage.getItem('email');
    }

    get getFirstName() {
        return localStorage.getItem('firstName');
    }

    get getLastName() {
        return localStorage.getItem('lastName');
    }

    get getUserId() {
        let userId = localStorage.getItem('userId') ? localStorage.getItem('userId') : "0";
        return parseInt(userId!);
    }

    get getLandingPage() {
        let landingPage = localStorage.getItem('landingPage') ? localStorage.getItem('landingPage') : "AlertViewer";
        return landingPage!;
    }

    get getLastAccountAccess() {
        // new Date(Date.parse(cSharpDateTimeString)) will give you the DateTime in JS.
        if (this.lastAccountAccess)
            return new Date(Date.parse(this.lastAccountAccess));
        else {
            const lastAccess = localStorage.getItem('lastAccountAccess');
            return lastAccess ? new Date(Date.parse(lastAccess)) : "";
        }
    }

    get getUserDetailComputed(): IUpdateUser {
        return this.userDetail;
    }

    /*
    This function is used to reset all observables to their initial values.  
    */

    reset = () => {
        this.error = '';
        this.inProgress = false;
        this.isAuthenticated = false;
        this.isAgent = false;
        this.lastAccountAccess = '';
        this.userType = UserType.Personal;
        this.logoutError = "";
        this.logoutInprogress = false;
        this.logoutSuccess = false;
        this.loginFormData = {
            email: "",
            password: "",
            rememberMe: false,
        }
        this.contactUsingMailState = { ...this.initialStateValue };
        this.trainerContactUsInfoError = "";
        this.trainerContactUsInfoSuccess = false;
    }


    /*
    This function is used to reset Logout observables to their initial values.  
    */
    resetLogout = () => {
        this.logoutError = "";
        this.logoutInprogress = false;
        this.logoutSuccess = false;
    }

    /*
    Function used to reset the loginUserState.
    */
    resetLoginUser = () => {
        this.loginUserState = { ...this.initialStateValue }
    }


    /*
    This function is used to reset add/update user state.
    */
    resetAddUpdateUser = () => {
        this.userDetail = updateUserInitialState;
        this.addUpdateUserState = { ...this.initialStateValue };
    }

    resetOtpGenerationState = () => {
        this.otpGenerationState = { ...this.initialStateValue };
    }

    resetOtpVerificationState = () => {
        this.otpVerificationState = { ...this.initialStateValue }; 
    }

    /*
    Function used to reset the Change Password state
    */
    resetChangePasswordState = () => {
        this.changePasswordState = { ...this.initialStateValue }
    }

    /*
        Function to reset the Forgot Password state
    */
    resetForgotPasswordState = () => {
        this.forgotPasswordState = { ...this.initialStateValue}
    }

    resetVerifyResetPasswordTokenState = () => {
        this.verifyResetPasswordTokenState = { ...this.initialStateValue}
    }

    /*
        Function used to reset the Reset Password state
    */
    resetResetPasswordState = () => {
        this.resetPasswordState = { ...this.initialStateValue}
    }

    resetFinalPaymentState = () => {
        this.finalPaymentState = { ...this.initialPaymentStateValue}
    }

    resetCouponCodeDiscountState = () => {
        this.couponCodeDiscountState = { ...this.initialStateValue};
    }

    /*
    This function is used to register a new user.
    */
    addUser = (user: IAddUser, lang: string) => {
        const currentDate = new Date();
        const data = {
            Email: user.Email?.trim(),
            FirstName: user.FirstName?.trim(),
            LastName: user.LastName?.trim(),
            DateOfBirth: new Date(user.DateOfBirth).toISOString(),
            Gender: Number(user.Gender),
            Nation: user.Nation?.trim(),
            CountryOfResidence: user.CountryOfResidence?.trim(),
            Password: base64.encode(user.Password),
            Education: Number(user.Education),
            WorkPosition: Number(user.WorkPosition),
            JobSector: Number(user.JobSector),
            Referent: user.Referent?.trim(),
            Created_Date: currentDate.toISOString(),
            Updated_Date: currentDate.toISOString(),
            Language: lang
        }

        this.addUpdateUserState.inProgress = true;
        return baseService.postRequest(URLConstants.AddUser, data)
            .then((response: IApiResponse<IApiSuccessResponse<IAuthResponse>>) => {
                if (response.data.Error) {
                    this.error = response.data.Message;
                    toast.error(response.data.Message);
                }
                else {
                    // console.log(response.data.Data);
                    let data = response.data.Data;
                    localStorage.setItem('email', data.Email);
                    localStorage.setItem('userId', data.UserId);
                    localStorage.setItem('token', data.AccessToken);
                    localStorage.setItem('isAgent', data.IsAgent.toString());
                    localStorage.setItem('darkTheme', 'false');
                    localStorage.setItem('userType', data.UserType);
                    localStorage.setItem('lastAccountAccess', data.LastAccountAccess);
                    this.isAuthenticated = true;
                    this.token = data.AccessToken;
                    this.isAgent = data.IsAgent;
                    this.userType = data.UserType;
                    this.lastAccountAccess = data.LastAccountAccess;
                    this.addUpdateUserState.success = true;
                    toast.success(formatMessage("register_success"))
                    this.resetOtpGenerationState();
                    this.resetOtpVerificationState();
                }
            })
            .catch((err: string) => {
                this.addUpdateUserState.error = err;
                toast.error(formatMessage(err));
                return null;
            })
            .finally(action(() => {
                this.addUpdateUserState.inProgress = false;
            }));
    }

    /*
    This function is used to update user info.
    */
    updateUser = (user: IUpdateUser) => {
        var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds

        this.addUpdateUserState.inProgress = true;
        const data = {
            FirstName: user.FirstName.trim(),
            LastName: user.LastName.trim(),
            DateOfBirth: new Date(new Date(user.DateOfBirth).getTime() - tzoffset).toISOString(),
            Gender: Number(user.Gender),
            Nation: user.Nation.trim(),
            CountryOfResidence: user.CountryOfResidence.trim(),
        };

        return baseService.putRequest(URLConstants.UpdateUserInfo, data)
            .then((response: any) => {
                // console.log("success");
                // .then((response: IApiResponse<IApiSuccessResponse<IAddUser>>) => {
                toast.success(formatMessage("updated_success"));
                this.addUpdateUserState.success = true;
            })
            .catch((err: string) => {
                this.addUpdateUserState.success = false;
                this.addUpdateUserState.error = err;
                toast.error(formatMessage(err));
            })
            .finally(action(() => {
                this.addUpdateUserState.inProgress = false;
            }));
    }

    /*
    This function is used to get the user info.
    */
    getUserDetail = () => {
        // this.inProgress = true;
        const url = `${URLConstants.GetUserDetail}/${this.getEmail}`
        return baseService.getRequest(url)
            .then((response: IApiResponse<IApiSuccessResponse<IUser>>) => {
                // console.log("success");
                // this.addUpdateUserState.success = true;
                this.userDetail.FirstName = response.data.Data.FirstName;
                this.userDetail.LastName = response.data.Data.LastName;
                this.userDetail.Gender = response.data.Data.Gender;
                this.userDetail.DateOfBirth = response.data.Data.DateOfBirth;
                this.userDetail.Nation = response.data.Data.Nation;
                this.userDetail.CountryOfResidence = response.data.Data.CountryOfResidence;
                localStorage.setItem('firstName', response.data.Data.FirstName);
                localStorage.setItem('lastName', response.data.Data.LastName);
            })
            .catch((err: string) => {
                // this.addUpdateUserState.success = false;
                // this.addUpdateUserState.error = err;
                toast.error(formatMessage(err));
            })
            .finally(action(() => {
                // console.log("Progress stopped.");
                // this.addUpdateUserState.inProgress = false;
            }));
    }

    /*
    This function is used to Authenticate User and get token (if authentic) from API.  
     (Updated By:Deepak Paliwal, Date:10-March-2023)
    */
    login = (data: ILogin) => {
        this.inProgress = true;
        this.error = '';
        this.loginFormData = JSON.parse(JSON.stringify(data));
        // const authData = { Email: data.email, Password: base64.encode(data.password) };
        const authData = { Email: data.email, Password: base64.encode(data.password) };
        // console.log(URLConstants.Authenticate);
        return baseService.postRequest(URLConstants.Authenticate, authData)
            .then((response: IApiResponse<IApiSuccessResponse<IAuthResponse>>) => {
                if (response.data.Error) {
                    this.error = response.data.Message;
                }
                else {
                    // console.log(response.data.Data);
                    let data = response.data.Data;
                    localStorage.setItem('email', data.Email);
                    localStorage.setItem('userId', data.UserId);
                    localStorage.setItem('token', data.AccessToken);
                    localStorage.setItem('isAgent', data.IsAgent.toString());
                    localStorage.setItem('darkTheme', 'false');
                    localStorage.setItem('userType', data.UserType);
                    localStorage.setItem('lastAccountAccess', data.LastAccountAccess);
                    this.isAuthenticated = true;
                    this.token = data.AccessToken;
                    this.isAgent = data.IsAgent;
                    this.userType = data.UserType;
                    this.lastAccountAccess = data.LastAccountAccess;
                    this.resetOtpGenerationState();
                    this.resetOtpVerificationState();
                    // console.log(data.IsAgent);
                }
            })
            .catch((err: string) => {
                this.error = err;
            })
            .finally(action(() => {
                this.inProgress = false;
            }));
    }

    /*
    This function is used to reset all observables to their initial values and clear local storage.  
    */
    logout = () => {
        this.logoutInprogress = true;
        this.reset();
        const lang: any = localStorage.getItem("language")
        localStorage.clear();
        localStorage.setItem("language", lang ? lang : "en");
        this.logoutSuccess = true;
        // return baseService.postRequest(URLConstants.Logout, {})
        //     .then((response: IApiResponse<any>) => {
        //         if(response.data.Error)
        //         {
        //             this.logoutError = response.data.Message;
        //         }
        //         else{
        //             this.reset();
        //             const lang:any = localStorage.getItem("language")
        //             localStorage.clear();
        //             localStorage.setItem("language", lang ? lang : "en");
        //             this.logoutSuccess = true;
        //         }
        //     })
        //     .catch((err: string) => {
        //         this.logoutError = err;
        //     })
        //     .finally(action(() => {
        //         this.logoutInprogress = false;
        //     }));
    }

    otpGenerationOnRegister = (email: string, referent:string, lang: string) => {
        this.otpGenerationState.inProgress = true;
        this.otpGenerationState.error = '';
        const otpGenData = { 
            "Email": email,
            "Referent":referent,
            "Language": lang
        };
        return baseService.postRequest(URLConstants.GenerateOtpOnRegister, otpGenData)
            .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
                if (response.data.Error) {
                    this.otpGenerationState.error = response.data.Message;
                }
                else {
                    let data = response.data.Data;
                    if (data){
                        this.otpGenerationState.success = true;
                        toast.success(`OTP ${formatMessage("sent_success")}`);
                    }
                }
            })
            .catch((err: string) => {
                this.otpGenerationState.error = err;
                toast.error(formatMessage(err));
                this.otpGenerationState.success = false;
            })
            .finally(action(() => {
                this.otpGenerationState.inProgress = false;
            }));
    }

    otpGenerationOnLogin = (loginValues: ILogin, lang: string) => {
        this.otpGenerationState.inProgress = true;
        this.otpGenerationState.error = '';
        const otpGenData = { 
            "Email": loginValues.email,
            "Password": base64.encode(loginValues.password),
            "Language": lang
        };
        return baseService.postRequest(URLConstants.GenerateOtpOnLogin, otpGenData)
            .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
                if (response.data.Error) {
                    this.otpGenerationState.error = response.data.Message;
                }
                else {
                    let data = response.data.Data;
                    if (data){
                        this.otpGenerationState.success = true;
                        toast.success(`OTP ${formatMessage("sent_success")}`);
                    }
                }
            })
            .catch((err: string) => {
                this.otpGenerationState.error = err;
                toast.error(formatMessage(err));
                this.otpGenerationState.success = false;
            })
            .finally(action(() => {
                this.otpGenerationState.inProgress = false;
            }));
    }


    otpVerification = (email: string, otp: string) => {
        this.otpVerificationState.inProgress = true;
        this.otpVerificationState.error = '';
        const otpData = { 
            "Email": email,
            "OTP": otp
        };
        return baseService.postRequest(URLConstants.VerifyOTPOnRegister, otpData)
            .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
                if (response.data.Error) {
                    this.otpVerificationState.error = response.data.Message;
                }
                else {
                    let data = response.data.Data;
                    if (data){
                        this.otpVerificationState.success = true;
                    }
                }
            })
            .catch((err: string) => {
                this.otpVerificationState.error = err;
                this.otpVerificationState.success = false;
            })
            .finally(action(() => {
                this.otpVerificationState.inProgress = false;
            }));
    }

    authenticateUser = (email: string, otp: string, token: string, password: string) => {
        this.otpVerificationState.inProgress = true;
        this.otpVerificationState.error = '';
        const otpData = { 
            "Email": email,
            "OTP": otp,
            "Token": token,
            "Password": base64.encode(password)
        };
        if (token && !otp) {
            this.otpGenerationState.inProgress = true;
            this.otpGenerationState.error = '';
        }
        return baseService.postRequest(URLConstants.VerifyOTPOnLogin, otpData)
            .then((response: IApiResponse<IApiSuccessResponse<IAuthResponse>>) => {
                if (response.data.Error) {
                    this.otpVerificationState.error = response.data.Message;
                    this.otpVerificationState.success = false;
                    if (token && !otp) {
                        this.otpGenerationState.success = false;
                        this.otpGenerationState.error = response.data.Message;
                    }
                }
                else {
                    this.otpVerificationState.success = true;
                    if (token && !otp) {
                        this.otpGenerationState.success = false;
                    }
                    let data = response.data.Data;
                    localStorage.setItem('email', data.Email);
                    localStorage.setItem('userId', data.UserId);
                    localStorage.setItem('token', data.AccessToken);
                    localStorage.setItem('isAgent', data.IsAgent.toString());
                    localStorage.setItem('darkTheme', 'false');
                    localStorage.setItem('userType', data.UserType);
                    localStorage.setItem('lastAccountAccess', data.LastAccountAccess);
                    this.isAuthenticated = true;
                    this.token = data.AccessToken;
                    this.isAgent = data.IsAgent;
                    this.userType = data.UserType;
                    this.lastAccountAccess = data.LastAccountAccess;
                    this.resetOtpGenerationState();
                    this.resetOtpVerificationState();
                }
            })
            .catch((err: string) => {
                toast.error(formatMessage(err));
                this.otpVerificationState.error = err;
                this.otpVerificationState.success = false;
                if (token && !otp) {
                    this.otpGenerationState.success = false;
                    this.otpVerificationState.error = err;
                }
                // this.resetOtpGenerationState();
                // this.resetOtpVerificationState();
            })
            .finally(action(() => {
                this.otpVerificationState.inProgress = false;
                if (token && !otp) {
                    this.otpGenerationState.inProgress = false;
                }
                // this.resetOtpGenerationState();
                // this.resetOtpVerificationState();
            }));
    }

    /*
        Function used to login the user by email only. (Must have ADMIN Rights for this).
    */
    loginUser = (email: string) => {
        const data = {
            Email: email
        }
        this.loginUserState.inProgress = true;
        return baseService.postRequest(URLConstants.LoginUser, data)
            .then((response: IApiResponse<IApiSuccessResponse<IAuthResponse>>) => {
                if (response.data.Error) {
                    this.loginUserState.error = response.data.Message;
                }
                else {
                    this.loginUserState.success = true;
                    let data = response.data.Data;
                    localStorage.setItem('email', data.Email);
                    localStorage.setItem('userId', data.UserId);
                    localStorage.setItem('token', data.AccessToken);
                    localStorage.setItem('isAgent', data.IsAgent.toString());
                    localStorage.setItem('darkTheme', 'false');
                    localStorage.setItem('userType', data.UserType);
                    localStorage.setItem('lastAccountAccess', data.LastAccountAccess);
                    this.isAuthenticated = true;
                    this.token = data.AccessToken;
                    this.isAgent = data.IsAgent;
                    this.userType = data.UserType;
                    this.lastAccountAccess = data.LastAccountAccess;
                }
            })
            .catch((err: string) => {
                this.loginUserState.error = err;
                toast.error(formatMessage(err));
            })
            .finally(action(() => {
                this.loginUserState.inProgress = false;
            }));
    };


    /*
        Function used to change the password.
    */
    changePassword = (values: IChangePassword) => {
        this.changePasswordState.inProgress = true;
        const data = {
            "CurrentPassword": base64.encode(values.currentPassword),
            "NewPassword": base64.encode(values.newPassword),
            "ConfirmPassword": base64.encode(values.confirmPassword)
        }

        return baseService.putRequest(URLConstants.ChangePassword, data)
            .then((response: IApiResponse<IApiSuccessResponse<string>>) => {
                if (response.data.Error) {
                    this.changePasswordState.error = response.data.Message;
                    toast.error(response.data.Message);
                }
                else {
                    this.changePasswordState.success = true;
                    let data = response.data.Data;
                    localStorage.setItem('token', data);
                    toast.success(formatMessage("forgot_reset_password_message"));
                }
            })
            .catch((err: string) => {
                this.changePasswordState.error = err;
                if (err == "IncorrectPassword")
                    toast.error(formatMessage("IncorrectOldPassword"));
                else
                    toast.error(formatMessage(err));
            })
            .finally(action(() => {
                this.changePasswordState.inProgress = false;
            }));
    }


    contactUsResponse = (data: IContactUsingEmail) => {
        this.contactUsingMailState.inProgress = true;
        const url = URLConstants.ContactUsingEmail;
        const ContactData = { name: data.name, email: data.email, message: data.message, subject: data.subject };
        return baseService.postRequest(url, ContactData)
            .then((response: IApiResponse<IApiSuccessResponse<IAuthResponse>>) => {
                if (response.data.Error) {
                    this.error = response.data.Message;
                    this.contactUsingMailState.error = this.error;
                    toast.error(response.data.Message);
                }
                else {
                    // console.log(response.data.Message);
                    this.contactUsingMailState.success = true;
                    toast.success(formatMessage("sent_success"));
                }
            })
            .catch((err: string) => {
                this.error = err;
                this.contactUsingMailState.error = err;
            })
            .finally(action(() => {
                this.contactUsingMailState.inProgress = false;
            }));
    };

    resetContactInfo = () => {
        this.contactUsingMailState = { ...this.initialStateValue };
    }

    trainerContactUs = (data: ITrainerContactUsInfo) => {
        const url = URLConstants.TrainerContactUs;
        const ContactData = { name: data.name, email: data.email, message: data.message };
        return baseService.postRequest(url, ContactData)
            .then((response: IApiResponse<IApiSuccessResponse<IAuthResponse>>) => {
                if (response.data.Error) {
                    this.error = response.data.Message;
                    this.trainerContactUsInfoError = this.error;
                    toast.error(response.data.Message);
                }
                else {
                    // console.log(response.data.Message);
                    this.trainerContactUsInfoSuccess = true;
                    toast.success(formatMessage('sent_success'));
                }
            })
            .catch((err: string) => {
                this.error = err;
                this.trainerContactUsInfoError = err;
            })
    }

    resetTrainerContactInfo = () => {
        this.trainerContactUsInfoError = "";
        this.trainerContactUsInfoSuccess = false;
    }


    /*
        Helper Function to format date
    */
    formatDate(date: Date) {
        // Reset a Date's time to midnight
        date.setHours(0, 0, 0, 0);
        return [
            date.getFullYear(),
            this.padTo2Digits(date.getMonth() + 1),
            this.padTo2Digits(date.getDate()),
        ].join('-');
    }

    // Format a date to YYYY-MM-DD (or any other format)
    padTo2Digits(num: Number) {
        return num.toString().padStart(2, '0');
    }

    /*
       Function for Forgotten password
    */
    forgotPassword = (userEmail: string) => {
        const url = URLConstants.ForgotPassword;
        this.forgotPasswordState.inProgress = true;
        const data =
        {
            UserEmail: userEmail
        };
        return baseService.postRequest(url, data)
            .then((response: any) => {
                // console.log("success");
                this.forgotPasswordState.success = true;
            })
            .catch((err: string) => {
                this.forgotPasswordState.error = err;
                return null;
            })
            .finally(action(() => {
                this.forgotPasswordState.inProgress = false;
            }));
    }

    verifyResetPasswordToken = (token: string) => {
        this.verifyResetPasswordTokenState.inProgress = true;
        const url = `${URLConstants.VerifyResetPasswordToken}?token=${token}`
        return baseService.getRequest(url)
            .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
                if (response.data.Error) {
                    this.verifyResetPasswordTokenState.error = response.data.Message;
                }
                else {
                    this.verifyResetPasswordTokenState.success = true;
                }
            })
            .catch((err: string) => {
                this.verifyResetPasswordTokenState.error = err;
            })
            .finally(action(() => {
                this.verifyResetPasswordTokenState.inProgress = false;
            }));
    }

    /*
        Function used to change the password.
    */
    resetPassword = (values: IResetPassword) => {
        this.resetPasswordState.inProgress = true;
        const data = {
            "Token": values.token,
            "NewPassword": base64.encode(values.newPassword),
            "ConfirmedPassword": base64.encode(values.confirmPassword)
        }
        // console.log(data);
        return baseService.putRequest(URLConstants.ResetPassword, data)
            .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
                if (response.data.Error) {
                    this.resetPasswordState.error = response.data.Message;
                }
                else {
                    this.resetPasswordState.success = true;
                }
            })
            .catch((err: string) => {
                this.resetPasswordState.error = err;
                // if (err == "IncorrectPassword")
                //     toast.error(formatMessage("Current Password is incorrect."));
                // else
                //     toast.error(formatMessage(err));
            })
            .finally(action(() => {
                this.resetPasswordState.inProgress = false;
            }));
    }


    /* ************************** Test ************************** */

    /*
      Function to get all the test
    */
    getAllTest = (currentPage:number,pagerSize:number,) => {
        this.getAllUserTestState.inProgress = true;
        var userId = this.getUserId;
        var url = URLConstants.GetUserSpecificTests + `?userId=${userId}` + '&PageNo=' + currentPage + '&PageSize=' + pagerSize;
        this.testDataList = GetAllTestInitialState;
        return baseService
            .getRequest(url)
            .then((response: IApiResponse<IApiSuccessResponse<IGetAllTest>>) => {
                if (response.data.Error) {
                    this.error = response.data.Message;
                    this.getAllUserTestState.error  = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else {
                    this.testDataList = response.data.Data;
                    this.testPaginationState.PagingDetails = response.data.Data.PagingDetails;
                    this.getAllUserTestState.success = true;
                }
            })
            .catch((err: string) => {
                // console.log(err);
                toast.error(formatMessage(err));
                this.getAllUserTestState.error = err;
            })
            .finally(action(() => {
                this.getAllUserTestState.inProgress = false;
            }));

    }

    resetGetAllUserTestState = () => {
        this.getAllUserTestState ={...this.initialStateValue};
    }
   /* 
  function to update current page size
  */
  updateTestPageSize =(value:number,isRefreshPage:boolean = false)=>{
    this.testPaginationState.PagingDetails.PageSize = value;
    this.testPaginationState.PagingDetails.isPagerChange = true;
    if(isRefreshPage)
    {
      this.testPaginationState.PagingDetails.isRefreshPage = true;
    }
  }

 /* 
 function to update current page number
 */
  updateTestPageNumber =(value:number,isRefreshPage:boolean = false)=>{
    this.testPaginationState.PagingDetails.PageNo = value;
    this.testPaginationState.PagingDetails.isPagerChange = true;
    if(isRefreshPage)
    {
      this.testPaginationState.PagingDetails.isRefreshPage = true;
    }
   }

   resetTestPagingState = ()=>{
    this.testPaginationState.PagingDetails.isPagerChange = false;
    this.testPaginationState.PagingDetails.isRefreshPage = false;
}
    /*
    Function to delete the test
  */
    deleteTest = (testId: number) => {
        this.deleteTestState.inProgress = true;
        const url = URLConstants.DeleteTest + "?testId=" + testId;
        return baseService
            .deleteRequest(url)
            .then((responseData: any) => {
                if (responseData.status === 200) {
                    this.deleteTestState.success = true;
                    // console.log(responseData);
                }
            })
            .catch((err: string) => {
                // console.log(err);
                this.deleteTestState.error = err;
                toast.error(formatMessage(err));
                return null;
            })
            .finally(
                action(() => {
                    this.deleteTestState.inProgress = false;
                })
            );
    }

    /*
    Function to reset the deleteTestState
  */
    resetDeleteTest = () => {
        this.deleteTestState = { ...this.initialStateValue }
    }

    /*
    Function to reset the Requests
  */
    resetCancelRequest=()=>{
        this.CancelRequestState = {...this.initialStateValue}
    }

    resetDeleteRequest=()=>{
        this.DeleteRequestState = {...this.initialStateValue}
    }

    resetDeleteFriends=()=>{
        this.DeleteFriendsState = {...this.initialStateValue}
    }

    resetUpdateStatus=()=>{
        this.UpdateStatusState = {...this.initialStateValue}
    }

    postSendFriendRequest = (SendFriendRequests: any) => {
        this.addSendFriendRequestState.inProgress = true;
        return baseService.postRequest(URLConstants.SendFriendRequest, SendFriendRequests)
            .then((response: IApiResponse<IApiSuccessResponse<IAddSendFriendRequest>>) => {
                if (response.data.Error) {
                    this.addSendFriendRequestState.error = formatMessage(response.data.Message);
                    toast.error(formatMessage(response.data.Message));
                }
                else {
                    this.addSendFriendRequestState.success = true;
                    toast.success(formatMessage("sent_success"));
                }
            })
            .catch((err: string) => {
                toast.error(formatMessage(err));
            })
            .finally(action(() => { this.addSendFriendRequestState.inProgress = false; }));
    }

    resetDiscountState = () => {
        this.couponCodeDiscount = -1;
        this.couponCodeDiscountState = {...this.initialStateValue};
    }

    resetPaymentState = () => {
        this.couponCodeDiscount = -1;
        this.paymentState = {...this.initialStateValue};
    }

    getAllFriendRequests = (requestType?: SendFriendRequestEnum) => {
        this.getSendFriendRequestState.inProgress = true;
        var url = `${URLConstants.GetAllFriendRequest}/?requestType=${requestType}`;
        return baseService.getRequest(url)
            .then((response: any) => {
                this.getSendFriendRequestAll = response.data.Data;
                this.getSendFriendRequestState.success = true
            })
            .catch((err: string) => {
                toast.error(formatMessage(err));
            })
            .finally(action(() => {
                this.getSendFriendRequestState.inProgress = false;
            }));
    }

    getSendFriendRequestsSend = (requestType?: SendFriendRequestEnum) => {
        this.getSendFriendRequestStateSend.inProgress = true;
        var url = `${URLConstants.GetAllFriendRequest}/?requestType=${requestType}`;
        return baseService.getRequest(url)
            .then((response: any) => {
                if (requestType == SendFriendRequestEnum.Send) {
                    this.getSendFriendRequestSend = response.data.Data;
                }
                this.getSendFriendRequestStateSend.success = true
            })
            .catch((err: string) => {
                toast.error(formatMessage(err));
            })
            .finally(action(() => {
                this.getSendFriendRequestStateSend.inProgress = false;
            }));
    }
    getSendFriendRequestsReceived = (requestType?: SendFriendRequestEnum) => {
        this.getSendFriendRequestStateReceived.inProgress = true;
        var url = `${URLConstants.GetAllFriendRequest}/?requestType=${requestType}`;
        return baseService.getRequest(url)
            .then((response: any) => {
                if (requestType == SendFriendRequestEnum.Received) {
                    this.getSendFriendRequestReceived = response.data.Data;
                }
                this.getSendFriendRequestStateReceived.success = true
            })
            .catch((err: string) => {
                toast.error(formatMessage(err));
            })
            .finally(action(() => {
                this.getSendFriendRequestStateReceived.inProgress = false;
            }));
    }

    UpdateStatus = (RequestId: number) => {
        const url = URLConstants.UpdateStatus;
        this.UpdateStatusState.inProgress = true;
        const data =
        {
            requestId: RequestId
        };
        return baseService.putRequest(url, data)
            .then((response: any) => {
                this.UpdateStatusState.success = true;
            })
            .catch((err: string) => {
                this.UpdateStatusState.error = err;
                return null;
            })
            .finally(action(() => {
                this.UpdateStatusState.inProgress = false;
            }));
    }
    DeleteRequest = (requestId: number) => {
        this.DeleteRequestState.inProgress = true;
        const url = URLConstants.DeleteRequest + "?requestId="+ requestId;
        return baseService
        .deleteRequest(url)
        .then((responseData: any) => {
          if (responseData.status === 200)
          {
            this.DeleteRequestState.success = true;
            // console.log(responseData);
          }
        })
        .catch((err: string) => {
        //   console.log(err);
          this.DeleteRequestState.error = err;
          toast.error(formatMessage(err));
          return null;
        })
        .finally(
          action(() => {
            this.DeleteRequestState.inProgress = false;
          })
        );
    }

    DeleteFriends = (requestId: number) => {
        this.DeleteFriendsState.inProgress = true;
        const url = URLConstants.DeleteFriends + "?requestId="+ requestId;
        return baseService
        .deleteRequest(url)
        .then((responseData: any) => {
          if (responseData.status === 200)
          {
            this.DeleteFriendsState.success = true;
            // console.log(responseData);
          }
        })
        .catch((err: string) => {
        //   console.log(err);
          this.DeleteFriendsState.error = err;
          toast.error(formatMessage(err));
          return null;
        })
        .finally(
          action(() => {
            this.DeleteFriendsState.inProgress = false;
          })
        );
    }

    CancelRequest = (requestId: number) => {
        this.CancelRequestState.inProgress = true;
        const url = URLConstants.CancelRequest + "?requestId="+ requestId;
        return baseService
        .deleteRequest(url)
        .then((responseData: any) => {
          if (responseData.status === 200)
          {
            this.CancelRequestState.success = true;
            // console.log(responseData);
          }
        })
        .catch((err: string) => {
        //   console.log(err);
          this.CancelRequestState.error = err;
          toast.error(formatMessage(err));
          return null;
        })
        .finally(
          action(() => {
            this.CancelRequestState.inProgress = false;
          })
        );
    }


    getExpiryDate = (expiryDate: string) => {
        const expDate = new Date(expiryDate);
        // Extract year and month
        const year = expDate.getFullYear().toString().slice(-2); // Extract last two digits of year
        let month = (expDate.getMonth() + 1).toString(); // Months are zero indexed, so add 1

        // Pad single digit months with a leading zero
        if (month.length === 1) {
            month = '0' + month;
        }
        // Concatenate year and month in YYMM format
        return year + month;
    }

    /*
        Function which calls the API for paying the amount of Personal Plan, and applies coupon code, if any. 
    */
    personalPlanPayment = (cardDetail: IPaymentCard, productType: number, couponCode: string, lang: string, testId: string) => {
        this.paymentState.inProgress = true;
        this.finalPaymentState.inProgress = true;
        this.finalPaymentState.status = "Processing Payment..."
        const cardDetailJSON = {
            accountNumber: cardDetail.CardNumber,
            accountName: cardDetail.CardHolderName,
            expiryDate: this.getExpiryDate(cardDetail.ExpiryDate),
            securityCode: cardDetail.CVV,
        };
        var data = {
            CouponCode: couponCode,
            ProductType: Number(productType),
            CardDetails: base64.encode(JSON.stringify(cardDetailJSON)),
            Language: lang,
            TestId: productType == ProductType.Test ? Number(testId) : 0,
            // Is3DSecure: cardDetail.Is3DSecure
        }
        //console.log(data);

        const url = URLConstants.PersonalPlanPayment;
        return baseService.postRequest(url, data)
        .then((responseData: IApiResponse<IApiSuccessResponse<IPayment>>) => {
          if (responseData.data.Data.Status == "Paid") {
            this.paymentState.success = true;
            this.finalPaymentState.success = true;
            // this.finalPaymentState.status = "Payment Successful!"
            toast.success('Payment Successful!');
          }
          else if (responseData.data.Data.Status == "Pending")
          {
            this.paymentState.success = false;
            this.finalPaymentState.success = false;
            this.finalPaymentState.status = "Pending";
            this.finalPaymentState.threeDSHTML = responseData.data.Data.ThreeDSHTML;
            this.finalPaymentState.threeDSRedirectURL = responseData.data.Data.ThreeDSRedirectURL;
            toast.success('Redirecting...');
          }
          else {
            this.paymentState.success = false;
            this.finalPaymentState.success = false;
            if (responseData.data.Message.includes("code")) {
                const response: IPaymentResponse = JSON.parse(responseData.data.Message);
                this.finalPaymentState.error = 'Payment got failed. Please retry again.';
                const concatenatedMessages = response.details
                                                .map((detail: IPaymentErrorDetail) => detail.message)
                                                .join('\n'); // or any separator you prefer
                this.finalPaymentState.error = concatenatedMessages;
            }
            else {
                this.paymentState.success = false;
                this.finalPaymentState.success = false;
                this.finalPaymentState.error = 'Payment got failed. Please retry again.';
            }
            toast.error(formatMessage(responseData.data.Message));
          }
        })
        .catch((err: string) => {
          this.paymentState.success = false;
          this.finalPaymentState.success = false;
          this.paymentState.error = err;
          this.finalPaymentState.error = 'Payment got failed. Please retry again.';
          toast.error(formatMessage(err));
        })
        .finally(action(() => {
            this.paymentState.inProgress = false;
            this.finalPaymentState.inProgress = false;
          })
        );
    }
    

    /*
        Function to get the discount on the coupon code. 
    */
    getCouponCodeDiscount = (couponCode: string) => {
        this.couponCodeDiscountState.inProgress = true;
        const url = `${URLConstants.GetCouponCodeDiscount}?couponCode=${couponCode}`;
        return baseService.getRequest(url)
        .then((responseData: any) => {
          if (!responseData.error) {
            this.couponCodeDiscountState.success = true;
            // console.log(responseData);
            this.couponCodeDiscount = responseData.data.Data;
          }
        })
        .catch((err: string) => {
          this.couponCodeDiscountState.error = err;
          toast.error(formatMessage(err));
        //   return null;
        })
        .finally(action(() => {
            this.couponCodeDiscountState.inProgress = false;
          })
        );
    }

    getTestPrice = () => {
        this.getTestPriceState.inProgress = true;
        const url = URLConstants.GetTestPrice;
        return baseService.getRequest(url)
        .then((response:IApiResponse<IApiSuccessResponse<IPaymentSettingInfo>>)=>{
            if (!response.data.Error) {
                this.getTestPriceState.success = true;
                this.testPriceInfo = response.data.Data;
                //console.log(this.testPriceInfo);
            }
        })
        .catch((err: string) => {
          this.getTestPriceState.error = err;
          toast.error(formatMessage(err));
        })
        .finally(action(() => {
            this.getTestPriceState.inProgress = false;
          })
        );
    }

    validateRefererCode = (referer:string)=>{
        this.validateRefererState.inProgress = true;
        const url = URLConstants.ValidateReferer + "?referer="+referer;
        return baseService
        .getRequest(url)
        .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
          if (response.data.Error) {
              this.error = response.data.Message;
              this.validateRefererState.error = this.error;
              toast.error(formatMessage(response.data.Message));
          }
          else{
              this.Referer = response.data.Data;
              this.validateRefererState.success = true;
          }
        })
        .catch((err: string) => {
          this.validateRefererState.error = err;
          toast.error(formatMessage(err));
        })
        .finally(action(() => {
            this.validateRefererState.inProgress = false;
        }));
      }

      resetValidateRefererState = () => {
        this.validateRefererState = {...this.initialStateValue};
      }
    /*
      Computed Function to fetch all the test.
    */
    get allTest(): ITestInfo[] {
        const test = toJS(this.testDataList?.TestList);
        if (test && test?.length > 0) {
            // const allTestSortedData = toJS(this.testDataList?.TestList).sort((a: any, b: any) => Number(a.TestDate) - Number(b.TestDate));
            return this.testDataList?.TestList.map((test: ITestInfo) => {
                return {
                    // FirstName: test.FirstName,
                    // LastName: test.LastName,
                    TestId: test.TestId,
                    UserEmail: test.UserEmail,
                    TestDate: this.getNormalisedDate(test.TestDate),
                    TestType: test.TestType,
                    TrainerId:test.TrainerId,
                    IsPaidTest: test.IsPaidTest
                };
            });
        }
        return [];
    }

    /*
    Helper Function which converts unixTimestamp to Date in the format (dd/MM/yyyy, hh:mm:ss)
  */
    getNormalisedDate = (unixTimestamp: string) => {
        if (Number.parseInt(unixTimestamp)) {
            var date = new Date(Number(unixTimestamp) * 1000);
            const formattedDate = date.toLocaleDateString("en-GB", {
                year: "numeric",
                month: "numeric",
                day: "numeric",
            });

            const formattedTime = date.toLocaleTimeString("en-GB", {
                hour12: false, // Use 24-hour format
                hour: "numeric",
                minute: "numeric",
                second: "numeric",
            });

            return `${formattedDate} ${formattedTime}`;
        }
        return "NaN";
    }

   
    get allSendFriendRequest(): IGetSendFriendRequest[] {
        if (this.getSendFriendRequestAll.FriendRequestList && this.getSendFriendRequestAll.FriendRequestList.length > 0)
            return this.getSendFriendRequestAll.FriendRequestList.map((SendFriendRequest) => {
                return {
                    RequestId: SendFriendRequest.RequestId,
                    SenderEmail: SendFriendRequest.SenderEmail,
                    SenderId: SendFriendRequest.SenderId,
                    SenderName: SendFriendRequest.SenderName,
                    Status: SendFriendRequest.Status,
                    UserReceiverEmailId: SendFriendRequest.UserReceiverEmailId,
                    UserReceiverId: SendFriendRequest.UserReceiverId,
                    UserType: SendFriendRequest.UserType,
                    TestId: SendFriendRequest.TestId
                }
            })
        return [];
    }
    get allSendFriendRequestSend(): IGetSendFriendRequest[] {
        if (this.getSendFriendRequestSend.FriendRequestList && this.getSendFriendRequestSend.FriendRequestList.length > 0)
            return this.getSendFriendRequestSend.FriendRequestList.map((SendFriendRequest) => {
                return {
                    RequestId: SendFriendRequest.RequestId,
                    SenderEmail: SendFriendRequest.SenderEmail,
                    SenderId: SendFriendRequest.SenderId,
                    SenderName: SendFriendRequest.SenderName,
                    Status: SendFriendRequest.Status,
                    UserReceiverEmailId: SendFriendRequest.UserReceiverEmailId,
                    UserReceiverId: SendFriendRequest.UserReceiverId,
                    UserType: SendFriendRequest.UserType,
                    TestId: SendFriendRequest.TestId
                }
            })
        return [];
    }
    get allSendFriendRequestReceived(): IGetSendFriendRequest[] {
        if (this.getSendFriendRequestReceived.FriendRequestList && this.getSendFriendRequestReceived.FriendRequestList.length > 0)
            return this.getSendFriendRequestReceived.FriendRequestList.map((SendFriendRequest) => {
                return {
                    RequestId: SendFriendRequest.RequestId,
                    SenderEmail: SendFriendRequest.SenderEmail,
                    SenderId: SendFriendRequest.SenderId,
                    SenderName: SendFriendRequest.SenderName,
                    Status: SendFriendRequest.Status,
                    UserReceiverEmailId: SendFriendRequest.UserReceiverEmailId,
                    UserReceiverId: SendFriendRequest.UserReceiverId,
                    UserType: SendFriendRequest.UserType,
                    TestId: SendFriendRequest.TestId
                }
            })
        return [];
    }

    getOrderId = (): string => {
        // Generate a random string
        const randomString = Math.random().toString(36).substring(2);

        // Get the current timestamp
        const timestamp = Date.now().toString(36);

        // Combine the random string and timestamp
        const uniqueString = 'Order_' + randomString + timestamp;

        // Ensure the length does not exceed 70 characters
        return uniqueString.substring(0, 64);
    };

    getRequestId = (): string => {
        // Generate a random string
        const randomString = Math.random().toString(36).substring(2);

        // Get the current timestamp
        const timestamp = Date.now().toString(36);

        // Combine the random string and timestamp
        const uniqueString = 'Request_' + randomString + timestamp;

        // Ensure the length does not exceed 70 characters
        return uniqueString.substring(0, 70);
    };

    // captureTransation = async ( amount: number, transactionId: string, token: AuthToken): Promise<boolean> => {
    //     this.finalPaymentState.status = 'Finalyzing Payment...';
    //     let success = false;
    //     const captureTransactionBody = {
    //         requestId: this.getRequestId(),
    //         paymentMethodId: 'Credit',
    //         amount: {
    //             currencyCode: 'JPY',
    //             value: amount,
    //         },
    //         orderId: this.getOrderId(),
    //         requestProperty: {},
    //         labels: ['Sample Transaction'],
    //     };

    //     try {
    //         const url = URLConstants.CaptureTransaction(transactionId);
    //         const response = await axios.post(url, JSON.stringify(captureTransactionBody), {
    //             headers: {
    //                 Authorization: `Bearer ${token?.token}`,
    //                 'X-Routing-Key': token?.routingKey,
    //                 'Content-Type': 'application/json',
    //             },
    //         });
    //         console.log(response);
    //         if (response.data.status === 'SUCCESS') {
    //             success = true;
    //         }
    //     } catch (error: any) {
    //         console.log(error);
    //     }

    //     return success;
    // };
}

export default new AuthStore();
