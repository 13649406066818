import * as Yup from "yup";
import { UserTypeNumber } from "../../constants/enum";
import { formatMessage } from "../../translations/format-message";

export const LoginValidateSchema = Yup.object({
  email: Yup.string()
    .required("field_required"),
  password: Yup.string()
    .required("field_required"),
});

export const thresholdValidateSchema = Yup.object({
  Label: Yup.string()
    .trim()
    .required("field_required"),
  Value: Yup.number()
    .required("field_required"),
  Slug: Yup.string()
    .trim()
    .required("field_required"),
});

export const createTrainerAccountValidationSchema = Yup.object({
  Email: Yup.string().email().required('field_required'),
  FirstName: Yup.string().required('field_required').min(1,"min_name").max(50, 'max_name'),
  LastName: Yup.string().required('field_required').min(1,'min_family').max(50, 'max_family'),
  DateOfBirth: Yup.date().required('field_required').max(new Date(new Date().setDate(new Date().getDate())), "dob_valid"),
  Gender: Yup.string().required('field_required'),
  Nation: Yup.string().required('field_required'),
  CountryOfResidence: Yup.string().required('field_required'),
  SubscriptionExpiryDate: Yup.date().required('field_required').min(new Date(new Date().setDate(new Date().getDate() - 1)), "date_valid"),
  TrainerInvitesAvailable: Yup.number().required('field_required').min(0, "min_invites_available").max(100000, "Trainer's Invitation must be less than or equal to 100000"),
  IsAgent: Yup.bool().required('field_required'),
})

export const profileValidateSchema = Yup.object().shape({
  Email: Yup.string().email().required('field_required'),
  FirstName: Yup.string().required('field_required'),
  LastName: Yup.string().required('field_required'),
  UserType: Yup.string().required('field_required'),
  SubscriptionExpiryDate: Yup.date().when('UserType', ([UserType], schema) => {
    if (UserType == UserTypeNumber.Admin || UserType == UserTypeNumber.Trainer) {
      return schema.required('field_required').min(new Date(new Date().setDate(new Date().getDate() - 1)), "date_valid");
    }
    return schema.notRequired();
  }),
  TrainerInvitesAvailable: Yup.string().when('UserType', ([UserType], schema) => {
    return (UserType == UserTypeNumber.Admin || UserType == UserTypeNumber.Trainer) ? schema.required('field_required') : schema.notRequired()
  }),
  IsAgent: Yup.bool().required('field_required'),
})

export const registrationFormValidationSchema = Yup.object().shape({
  Email: Yup.string().email().required('field_required'),
  Password: Yup.string().required('field_required').min(8, 'password_valid').matches(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>_\-+'[\]])[A-Za-z\d!@#$%^&*(),.?":{}|<>_\-+'[\]]{8,}$/,
    "password_contain"
  ),
  FirstName: Yup.string().required('field_required').min(1,"min_name").max(50, 'max_name'),
  LastName: Yup.string().required('field_required').min(1,"min_family").max(50, 'max_family'),
  DateOfBirth: Yup.date().required('field_required').max(new Date(new Date().setDate(new Date().getDate())), "dob_valid"),
  Gender: Yup.string().required('field_required'),
  Nation: Yup.string().required('field_required'),
  CountryOfResidence: Yup.string().required('field_required'),
  Privacy: Yup.boolean().oneOf([true], 'field_required').required('field_required'),
});

export const forgotEmailValidateSchema = Yup.object({
  email: Yup.string()
    .required("field_required"),
});

export const ResetValidateSchema = Yup.object({
    newPassword: Yup.string().required('password_required').min(8, 'password_valid').matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>_\-+'[\]])[A-Za-z\d!@#$%^&*(),.?":{}|<>_\-+'[\]]{8,}$/,
      "password_contain"
    ),
    confirmPassword: Yup.string().required('confirm_password_required').oneOf([Yup.ref('newPassword')], 'password_match'),
});

export const CouponValidateSchema = Yup.object({
  couponName: Yup.string()
    .trim()
    .required("field_required")
    .max(50, formatMessage('coupon_max')),
  couponCode: Yup.string()
    .required("field_required")
    .matches(/^[0-9a-zA-Z ]+$/, 'coupon_char')
    .max(15, formatMessage("max_coupon"))
    .min(5, formatMessage("min_coupon")),
  discount: Yup.number()
    .required('Required')
    .min(1, formatMessage('min_discount'))
    .max(100, formatMessage('max_discount')),
    CouponCount: Yup.number()
    .required("field_required")
    .max(10000, formatMessage("max_coupon_count"))
    .min(1, formatMessage("min_coupon_count")),
  status: Yup.string()
    .required("field_required"),
  ValidUpto: Yup.date().required('field_required').min(new Date(new Date().setDate(new Date().getDate() - 1)), "date_valid"),
});


export const PaymentCardValidationSchema = Yup.object({
  CardNumber: Yup.string()
    .trim()
    .required("field_required")
    .max(16),
  CardHolderName: Yup.string()
    .required("field_required")
    .max(50),
  CVV: Yup.string()
    .required('field_required')
    .matches(/^\d+$/, 'The field should have digits only')
    .length(3),
  ExpiryDate: Yup.date()
    .required("field_required")
    .min(new Date(new Date().setDate(new Date().getDate() - 1)), "Expiry Date cannot be in the past."),
  // Is3DSecure: Yup.bool().required('field_required'),
});


export const UpdateUserValidationSchema = Yup.object().shape({
  FirstName: Yup.string().required('field_required'),
  LastName: Yup.string().required('field_required'),
  DateOfBirth: Yup.date().required('field_required').max(new Date(new Date().setDate(new Date().getDate())), "dob_valid"),
  Gender: Yup.string().required('field_required'),
  Nation: Yup.string().required('field_required'),
  CountryOfResidence: Yup.string().required('field_required'),
})


const requiredEmail = Yup.string().email(formatMessage('valid_email')).required('field_required');

export const SendEventMailValidationSchema = Yup.object().shape({
  RecipientUserEmail: Yup.array().of(requiredEmail).min(1, formatMessage('least_email')),
  // EmailField: Yup.string().email().required("Email is required"),
  // RecipientUserEmail: Yup.string().email().required("Email is required"),
  MessageTitle: Yup.string().required('field_required').min(5, "min_message"),
  MessageBody: Yup.string().required('field_required'),
})


export const ChangePasswordValidationSchema = Yup.object().shape({
  currentPassword: Yup.string().required('field_required'),
  newPassword: Yup.string().required('field_required').min(8, 'password_valid').matches(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>_\-+'[\]])[A-Za-z\d!@#$%^&*(),.?":{}|<>_\-+'[\]]{8,}$/,
    "password_contain"
  ),
  confirmPassword: Yup.string().required('field_required').oneOf([Yup.ref('newPassword')], 'password_match'),
});

export const SentTrainerInvitiesValidationSchema = Yup.object().shape({
  RecipientUserEmail: Yup.array().of(requiredEmail).min(1, formatMessage('least_email')),
  Message: Yup.string().required('field_required')
  .max(300, "max_message"),
  // GroupName: Yup.string().required('field_required'),
  TestType: Yup.string().required('field_required'),
  IsResultVisible: Yup.string().test('is boolean',
    'result_visible',
    (value) => value === "0" || value === "1")
});

export const SentFriendRequestValidationSchema = Yup.object().shape({
  RequestEmailIds: Yup.array().of(requiredEmail).min(1, formatMessage('least_email')),
});

export const validationSchema = Yup.object({
  name: Yup.string().trim().required('field_required'),
  email: Yup.string().email(formatMessage('valid_email')).required('field_required'),
  subject: Yup.string().trim().required('field_required'),
  message: Yup.string().trim().required('field_required'),
});

export const ValidateEvent = Yup.object().shape({
  EventName: Yup.string()
  .trim()
  .required('field_required')
  .max(50, formatMessage('max_event')),
  // Coupon: Yup.string().required(),
  // Note: Yup.string().required(),
  TestType: Yup.string().required('field_required'),
})

export const UpdateFiguredValidationSchema = Yup.object().shape({
  TestTakenCount: Yup.string().required('field_required'),
  TrainersCount: Yup.string().required('field_required'),
  CountriesCount: Yup.string().required('field_required'),
  CompaniesCount: Yup.string().required('field_required'),
  CoachesCount: Yup.string().required('field_required'),
 
})

export const CreatorValidateSchema = Yup.object().shape({
  CreatorName: Yup.string().required('field_required'),
  CreatorDesc_EN: Yup.string().required('field_required'),
  CreatorDesc_JP: Yup.string().required('field_required'),
  CreatorDesc_IT: Yup.string().required('field_required'),
  LinkedInURL: Yup.string().required('field_required'),
  MailId: Yup.string().required('field_required'),
})