import logo from '../../assets/Images/logo_original.png';
import React, { useEffect, useState } from 'react';
import './TopNavBar.css';
import { observer } from 'mobx-react';
import { FormattedMessage, injectIntl } from 'react-intl';
import LogoutHOC from '../../modules/auth/hoc/LogoutHOC';
import { formatMessage } from '../../translations/format-message';
import LogoutPopup from '../../modules/auth/components/Logout/LogoutPopup';
import RoutesConstants from '../Routes/routes.constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faBriefcase, faUserAlt, faUserTie, faChartColumn, faDesktopAlt } from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { Container, Navbar, Nav, NavDropdown } from "react-bootstrap";

import { useStore } from "../../contexts/StoreProvider";
import en_flag from '../../assets/Images/en.gif';
import it_flag from '../../assets/Images/it.gif';
import jp_flag from '../../assets/Images/jp.gif';
import facebookicon from '../../assets/Images/facebook.svg';
import youtubeicon from '../../assets/Images/youtube.svg';
import { Modal } from 'react-bootstrap';
import { UserType } from '../../constants/enum';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

interface IToken {
    invitiesId: number; // This value > 0 implies that the test is sent by a trainer.
}

const TopNavBar = (props: any) => {
    const { preferencesStore, authStore } = useStore();
    const { token } = useParams();
    const [searchParams] = useSearchParams();
    const [redirectUrl, setRedirectUrl] = useState<string|null>(searchParams.get('continue'));
    const navigate = useNavigate();
    const location = useLocation();
    const [lang, setLang] = useState<string>(preferencesStore.getLanguage);
    const [isTestLinkOpened, setIsTestLinkOpened] = useState<boolean>(true);
    const changeLang = (language: string) => {
        setLang(language);
        preferencesStore.changeLanguage(language);
    }

    const [colorChange, setColorchange] = useState(false);
    const changeNavbarColor = () => {
        if (window.scrollY >= 80) {
            setColorchange(true);
        } else {
            setColorchange(false);
        }
    };

    window.addEventListener("scroll", changeNavbarColor);

    const { getToken, isAuthenticated, userDetail, getUserDetail, getFirstName, addUpdateUserState, isAgent, getIsAgent, userType, getUserType, getLastAccountAccess, lastAccountAccess } = authStore;
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    useEffect(() => {
        if ((getToken || isAuthenticated))
            getUserDetail();
    }, []);

    useEffect(() => {
        if (addUpdateUserState.success && (getToken || isAuthenticated))
            getUserDetail();
    }, [addUpdateUserState.success])

    useEffect(() => {
        if (token) {
            const decodedToken = jwtDecode<IToken>(token);
            if (decodedToken?.invitiesId > 0) {
                setIsTestLinkOpened(() => true);
            }
        }
        else {
            if (location.pathname == RoutesConstants.TestSubmission) {
                setIsTestLinkOpened(() => true);
            }
            else {
                if (redirectUrl) {
                    const testURL = decodeURIComponent(redirectUrl);
                    let tokenDecoded = "";
                    if (testURL.includes(RoutesConstants.Life)) {
                        tokenDecoded = testURL.split(RoutesConstants.Life)[1];
                    }
                    else if (testURL.includes(RoutesConstants.Training)) {
                        tokenDecoded = testURL.split(RoutesConstants.Training)[1];
                    }
                    else if (testURL.includes(RoutesConstants.Racing)) {
                        tokenDecoded = testURL.split(RoutesConstants.Racing)[1];
                    }
                    else if (testURL.includes(RoutesConstants.Job)) {
                        tokenDecoded = testURL.split(RoutesConstants.Job)[1];
                    }
                    
                    if (tokenDecoded) {
                        setIsTestLinkOpened(() => true);
                    }

                }
                else {
                    setIsTestLinkOpened(() => false);
                }
            }
        }
    }, [token]);
    return (
        <>
            <div className={`main-header--wrapper d-print-none ${colorChange ? 'colorChange' : 'colorChange'}`}>
                {/* {(!isAuthenticated && !getToken) && */}
                <div className="extraNav">
                    <div className="container">
                        <div className='row'>
                            <div className='col-12 col-sm-6 col-md-6 text-center text-sm-start'>
                                {
                                    (getToken || isAuthenticated) &&
                                    <p className='text-muted me-2'><FormattedMessage id="last_account_access" /> : {getLastAccountAccess.toLocaleString()}</p>
                                }
                            </div>
                            <div className='col-12 col-sm-6 col-md-6 d-flex align-items-center justify-content-center justify-content-sm-end text-center'>
                                {
                                    (getToken || isAuthenticated) ? (
                                        <>
                                            <p className='text-dark me-2'><FormattedMessage id="hi" />, {(userDetail.FirstName) ? userDetail.FirstName : getFirstName} ! </p>
                                        </>
                                    ) : (
                                        <a
                                            href="javascript:;"
                                            className="text-dark me-2"
                                            onClick={() => navigate(RoutesConstants.Registration)}>
                                            <FormattedMessage id="create_your_account" />
                                        </a>
                                    )
                                }
                                <a className='social-link facebook' href="https://www.facebook.com/kaleidocompass/?fref=ts" target='blank'><img src={facebookicon} /></a>
                                <a className='social-link youtube' href="https://www.youtube.com/channel/UCsrvGXHWXezjinHHMi55Qkg" target='blank'><img src={youtubeicon} /></a>
                                <div className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle dropdown" href="javascript:void(0);" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <img src={lang == "en" ? en_flag : lang == "jp" ? jp_flag : it_flag} />
                                    </a>
                                    <div className="dropdown-menu dropdown-content clickable" aria-labelledby="navbarDropdown">
                                        <button className="dropdown-item" onClick={() => changeLang("it")}><img src={it_flag} />Italiano</button>
                                        <button className="dropdown-item" onClick={() => changeLang("jp")}><img src={jp_flag} />日本語</button>
                                        <button className="dropdown-item" onClick={() => changeLang("en")}><img src={en_flag} />English</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                {/* } */}
                <header className={`main-header container`} id="header">
                    {props.showLogoutPopup && (
                        <LogoutPopup modalClosed={props.logoutPopupToggleHandler} />
                    )}

                    <div className='header--navbar py-1 row align-items-xl-center'>
                        <div className="logo col-xl-2 col-lg-7 col-md-7 col-sm-5 col-5">
                            <button role='button' onClick={() => navigate(RoutesConstants.Home)}>
                                <img src={logo} alt="KaleidoCompass" className='img-fluid' />
                            </button>
                        </div>
                        <div className='col-xl-10 col-lg-5 col-md-5 col-sm-7 col-7'>
                            <Navbar expand="xl">
                                <Navbar.Toggle aria-controls="collapsibleNavbar" className='ms-auto' />
                                <Navbar.Collapse id="collapsibleNavbar">
                                    <Nav className="ms-auto">
                                        {(!isAuthenticated && !getToken)
                                            ?
                                            <>
                                                {!isTestLinkOpened &&
                                                <>
                                                    <Nav.Link href="/"><FormattedMessage id="home" /> <span className="sr-only">(current)</span></Nav.Link>
                                                    <NavDropdown title={formatMessage('the_idea')} id="the_idea">
                                                        <NavDropdown.Item href={RoutesConstants.KaleidoCompassIdea}><FormattedMessage id="what_kaleidocompass" /></NavDropdown.Item>
                                                        <NavDropdown.Item href={RoutesConstants.TestAim}><FormattedMessage id="What_test_aimed" /></NavDropdown.Item>
                                                        <NavDropdown.Item href={RoutesConstants.Instructions}><FormattedMessage id="instruction" /></NavDropdown.Item>
                                                    </NavDropdown>

                                                    <NavDropdown title={formatMessage('about_us')} id="about_us">
                                                        <NavDropdown.Item target='_blank' href={lang == "jp" ? "https://humanskills.jp/comp/" : "https://humanskills.jp/engcomp/"}><FormattedMessage id="The_company" /></NavDropdown.Item >
                                                        <NavDropdown.Item href={RoutesConstants.AboutCreators}><FormattedMessage id="The_creators" /></NavDropdown.Item>
                                                        <NavDropdown.Item target='_blank' href={lang == "jp" ? "https://humanskills.jp/comp/#ui-id-1" : "https://humanskills.jp/engcomp/#ui-id-1"}><FormattedMessage id="The_trainers" /></NavDropdown.Item >
                                                    </NavDropdown>

                                                    <Nav.Link role='button' target='_blank' href={lang == "jp" ? "https://humanskills.jp/sche/" : "https://humanskills.jp/engschedule/"}><FormattedMessage id="events_header" /></Nav.Link>
                                                        
                                                    <Nav.Link role='button' href={RoutesConstants.Prices}><FormattedMessage id="prices" /></Nav.Link>

                                                    <Nav.Link role='button' href={RoutesConstants.ContactUs}><FormattedMessage id="contact_us" /></Nav.Link>

                                                    <NavDropdown title={formatMessage('take_the_test')} id="take_the_test" className="nav-item--app nav-item--not-filled">
                                                        <NavDropdown.Item href={RoutesConstants.Life}><FormattedMessage id="life_menu" /></NavDropdown.Item>
                                                        <NavDropdown.Item href={RoutesConstants.Training}><FormattedMessage id="training_menu" /></NavDropdown.Item>
                                                        <NavDropdown.Item href={RoutesConstants.Racing}><FormattedMessage id="racing_menu" /></NavDropdown.Item>
                                                    </NavDropdown>
                                                </>
                                                }
                                                <Nav.Link className="nav-item--app nav-item--filled" role='button' href={RoutesConstants.Login}><FormattedMessage id="login" /></Nav.Link>

                                            </>

                                            :
                                            // User is authenticated.
                                            <>
                                                {!isTestLinkOpened && 
                                                    <>
                                                    {((userType != UserType.Admin && getUserType != UserType.Admin) &&
                                                        <Nav.Link href={RoutesConstants.Prices}><FormattedMessage id="prices" /></Nav.Link>
                                                    )}

                                                    <Nav.Link href={RoutesConstants.Dashboard}><FormattedMessage id="Dashboard" /></Nav.Link>

                                                    {((userType === UserType.Admin || getUserType === UserType.Admin) &&
                                                        <Nav.Link href={RoutesConstants.AdminDashboard}><FormattedMessage id="admin" /> </Nav.Link>
                                                    )}

                                                    {(
                                                        ((userType === UserType.Trainer || getUserType === UserType.Trainer) || (userType === UserType.Admin || getUserType === UserType.Admin)) &&
                                                        <Nav.Link href={RoutesConstants.TrainerDashboard}><FormattedMessage id="trainer" /> </Nav.Link>
                                                    )}

                                                    {((isAgent || getIsAgent) &&
                                                        <Nav.Link href={RoutesConstants.AgentDashboard}><FormattedMessage id="agent" /> </Nav.Link>
                                                    )}

                                                    {((userType === UserType.Admin || getUserType === UserType.Admin) &&
                                                        <Nav.Link href={RoutesConstants.Stats}><FormattedMessage id="Stats" /></Nav.Link>
                                                    )}

                                                    <NavDropdown title={formatMessage('take_the_test')} id="take_the_test" className="nav-item--app nav-item--not-filled">
                                                            <NavDropdown.Item href={RoutesConstants.Life}><FormattedMessage id="life_menu" /></NavDropdown.Item>
                                                            <NavDropdown.Item href={RoutesConstants.Training}><FormattedMessage id="training_menu" /></NavDropdown.Item>
                                                            <NavDropdown.Item href={RoutesConstants.Racing}><FormattedMessage id="racing_menu" /></NavDropdown.Item>
                                                    </NavDropdown>
                                                    </>
                                                }
                                                <Nav.Link className="logout nav-item--app nav-item--filled" style={{ cursor: 'pointer', display: 'inline-block' }} data-testid="Logout"
                                                        title={formatMessage("logout")}
                                                        onClick={props.logoutPopupToggleHandler}
                                                    ><FormattedMessage id="logout" />
                                                </Nav.Link>
                                            </>
                                        }
                                    </Nav>
                                </Navbar.Collapse>
                            </Navbar>
                        </div>
                    </div>
                </header>
            </div>
            {/* <div className="fake-header" style={{ paddingTop: '5.5em' }}></div> */}
        </>
    )
}
export default
    LogoutHOC(injectIntl(observer(TopNavBar)))
    ;