import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { observer } from "mobx-react";
import { useStore } from "../../../contexts/StoreProvider";
import { Button, Modal } from "react-bootstrap";
import { Field, Form, Formik } from "formik";
import { PaymentCardValidationSchema } from "../../../shared-components/FormikFormHandlers/FormikValidationSchema";
import Label from "../../../shared-components/Label/Label";
import FormikFormInput from "../../../shared-components/FormikFormHandlers/FormikFormInput";
import FormikDateFieldMUI from "../../../shared-components/FormikFormHandlers/FormikDateFieldMUI";
import { FormattedMessage } from "react-intl";
import LoaderButton from "../../../shared-components/Button/LoaderButton";
import { initialState as paymentCardInitialState } from "../../../core/initial-state/payment-card-state";
import IPaymentCard from "../../../models/Form/IPaymentCard";
import FormikFormCheckBox from "../../../shared-components/FormikFormHandlers/FormikFormCheckBox";
import { userInfo } from "os";
import { UserType } from "../../../constants/enum";

interface IProps {
  modalClosed: () => void;
  isLoading: boolean;
  submitHandler: (values: IPaymentCard) => void;
  error: string;
  status: string;
  threeDSRedirectURL: string;
}

const PaymentModal = (props: IProps) => {
    const { authStore } = useStore();
    const { userType, getUserType } = authStore;
    
    // If a redirect URL is found, redirect the user
    useEffect(() => {
        if (props.threeDSRedirectURL) {
            window.location.href = props.threeDSRedirectURL;
        }
    }, [props.threeDSRedirectURL]);

  return (
    <>
        <Modal show={true} centered
                onHide={props.modalClosed}
                backdrop="static"
                scrollable={true}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <FormattedMessage id='Enter Card Details'/>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Formik
                        initialValues={paymentCardInitialState}
                        validationSchema={PaymentCardValidationSchema}
                        enableReinitialize
                        validateOnBlur={false}
                        onSubmit={ async (values: IPaymentCard) => {
                            props.submitHandler(values);
                        }}
                    >
                        {({ values, errors, handleChange, handleBlur }) => (
                            <>
                            <Form id="addEditCouponForm" className="modal-form">
                                <div className="">
                                    <div className="form-group ">
                                        <Label
                                            required={true}
                                            className="fw-bold"
                                            label="card_number"
                                            htmlFor="CardNumber" />
                                        <Field
                                            data-testid="CardNumber"
                                            className="form-control"
                                            name="CardNumber"
                                            maxLength={16}
                                            type="number"
                                            invalidcharacter={[" "]}
                                            placeholder="card_number"
                                            as={FormikFormInput} />
                                    </div>
                                    <div className="form-group ">
                                        <Label
                                            required={true}
                                            className="fw-bold"
                                            label="card_holder_name"
                                            htmlFor="CardHolderName" />
                                        <Field
                                            data-testid="CardHolderName"
                                            className="form-control"
                                            name="CardHolderName"
                                            type="text"
                                            placeholder="card_holder_name"
                                            as={FormikFormInput} />
                                    </div>
                                    <div className="form-group ">
                                        <Label
                                            required={true}
                                            className="fw-bold"
                                            label="card_cvv"
                                            htmlFor="CVV" />
                                        <Field
                                            data-testid="CVV"
                                            className="form-control"
                                            name="CVV"
                                            type="text"
                                            maxLength={3}
                                            minLength={3}
                                            invalidcharacter={[" "]}
                                            placeholder="card_cvv"
                                            as={FormikFormInput} />
                                    </div>
                                    <div className="form-group ">
                                        <Label
                                            required={true}
                                            className="fw-bold"
                                            label="expiry_date"
                                            htmlFor="ExpiryDate"
                                        />
                                        <Field
                                            data-testid="ExpiryDate"
                                            className="form-control"
                                            name="ExpiryDate"
                                            min={Date.now()}
                                            cardExpiryformat={true}
                                            as={FormikDateFieldMUI}
                                        />
                                    </div>
                                    {/* { userType === UserType.Admin || getUserType === UserType.Admin &&
                                        <div className="form-group">
                                            <Label
                                                required={true}
                                                label="3D Secure"
                                                htmlFor="Is3DSecure"
                                                className="me-3"
                                            />
                                            <span className="form-switch">
                                                <Field
                                                    data-testid="test3DSecure"
                                                    className="form-check-input"
                                                    role="switch"
                                                    name="Is3DSecure"
                                                    type="checkbox"
                                                    id="inlineFormCheck"
                                                    as={FormikFormCheckBox}
                                                />
                                            </span>
                                        </div>
                                    } */}
                                </div>

                            {!props.isLoading && props.error !== "" &&
                                <span className="text-danger"><b>Error:</b> {props.error}</span>
                            }
                            </Form>
                            </>
                        )}
                    </Formik>
                </Modal.Body>
                <Modal.Footer>
                    <div className="d-flex justify-content-end ">
                        <Button
                            data-testid="Cancel"
                            variant="secondary"
                            onClick={props.modalClosed}
                            disabled={props.isLoading}
                            className="btnOkSize me-2">
                            <FormattedMessage id="button_cancel" />
                        </Button>
                        <LoaderButton
                            type="submit"
                            id="RegisterButton"
                            form="addEditCouponForm"
                            isLoading={props.isLoading}
                            // className="btnOkSize"
                            text="Pay Now"
                        />
                    </div>
                </Modal.Footer>
            </Modal>
    </>
  );
};

export default observer(PaymentModal);
