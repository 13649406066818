/*    
<summary>
   This functional component provides routing functionality. 
</summary> 
<returns>Returns JSX</returns>
*/
import React from "react";
import { Routes, Route } from "react-router-dom";
import Login from "../../modules/auth/Login";
import RoutesConstants from "./routes.constants";
import LoginLayoutRoute from "./LoginLayoutRoute";
import LayoutRoute from "./LayoutRoute";
import Logout from "../../modules/auth/Logout";
import Dashboard from "../../modules/dashboard/Dashboard";
import PageNotFound from "../PageNotFound/PageNotFound";
import Home from "../../modules/home/Home";
import StaticLayoutRoute from "./StaticLayoutRoute";
import KaleidoCompassIdea from "../../modules/idea/kaleidoCompassIdea/KaleidoCompassIdea";
import TestAim from "../../modules/idea/testAim/TestAim";
import Instructions from "../../modules/idea/instructions/Instructions";
import AboutCompany from "../../modules/about/company/AboutCompany";
import AboutCreators from "../../modules/about/creators/AboutCreators";
import AboutTrainers from "../../modules/about/trainers/AboutTrainers";
import Contact from "../../modules/contact/Contact";
import Pricing from "../../modules/pricing/pricing";
import TrainerContactUs from "../../modules/trainer/TrainerContactUs";
import Events from "../../modules/events/Events";
import Registration from "../../modules/registration/Registration";
import EventList from "../../modules/agent-dashboard/EventList/EventList";
import EventSummary from "../../modules/agent-dashboard/EventSummary/EventSummary";
import AgentRoute from "./AgentRoute";
import AdminDashboard from "../../modules/admin-dashboard/AdminDashboard";
import TrainerDashboard from "../../modules/trainer-dashboard/Dashboard/trainer-Dashboard";
import ManageGroups from "../../modules/trainer-dashboard/Groups/manage-groups";
import TestResult from "../../modules/test-result/TestResult";
import ForgotPasswordSetting from "../../modules/auth/components/ForgotPassword/ResetPassword";
// import ForgotEmail from "../../modules/auth/components/ForgotPassword/PasswordReset";
import TestLayout from "../../modules/test-layout/TestLayout";
import StatList from "../../modules/stats/StatList/StatList";
import PaymentPage from "../../modules/cart-page/PaymentPage";
import RechargePlan from "../../modules/cart-page/Trainer-RechargePlan/RechargePlan";
import { TestType } from "../../constants/enum";
import ForgotPassword from "../../modules/auth/components/ForgotPassword/ForgotPassword";
import ResetPassword from "../../modules/auth/components/ForgotPassword/ResetPassword";
import TestSubmission from "../../modules/test-result/TestSubmission";
import TestPayment from "../../modules/cart-page/TestPayment";
import TrainerDashboardPayment from "../../modules/cart-page/TrainerDashboardPayment";
import TrainerRechargePayment from "../../modules/cart-page/TrainerRechargePayment";
import PaymentConfirmation from "../../modules/cart-page/PaymentConfirmation";

const RoutesComponent = (props: any) => {
  return (
    <React.Fragment>
      <Routes>
        {/* <Route path="/" element={<Navigate to={RoutesConstants.Dashboard} />} /> */}
        <Route path={RoutesConstants.Home} element={<StaticLayoutRoute component={Home} />} />
        <Route path={RoutesConstants.KaleidoCompassIdea} element={<StaticLayoutRoute component={KaleidoCompassIdea} />} />
        <Route path={RoutesConstants.TestAim} element={<StaticLayoutRoute component={TestAim} />} />
        <Route path={RoutesConstants.Registration} element={<StaticLayoutRoute component={Registration} />} />
        <Route path={RoutesConstants.Instructions} element={<StaticLayoutRoute component={Instructions} />} />
        {/* <Route path={RoutesConstants.AboutCompany} element={<StaticLayoutRoute component={AboutCompany} />} /> */}
        <Route path={RoutesConstants.AboutCreators} element={<StaticLayoutRoute component={AboutCreators} />} />
        {/* <Route path={RoutesConstants.AboutTrainers} element={<StaticLayoutRoute component={AboutTrainers} />} /> */}
        <Route path={RoutesConstants.ContactUs} element={<StaticLayoutRoute component={Contact} />} />
        {/* <Route path={RoutesConstants.Events} element={<StaticLayoutRoute component={Events} />} /> */}
        <Route path={RoutesConstants.Login} element={<LoginLayoutRoute component={Login} />} />
        <Route path={RoutesConstants.Registration} element={<LoginLayoutRoute component={Registration} />} />

        <Route path={RoutesConstants.Logout} element={<LayoutRoute component={Logout} />} />
        <Route path={RoutesConstants.Prices} element={<StaticLayoutRoute component={Pricing} />} />
        <Route path={RoutesConstants.TrainerContactUs} element={<StaticLayoutRoute component={TrainerContactUs} />} />

        <Route path={RoutesConstants.AdminDashboard} element={<LayoutRoute component={AdminDashboard} />} />
        <Route path={RoutesConstants.Dashboard} element={<LayoutRoute component={Dashboard} />} />

        {/* AGENT ROUTES */}
        <Route path={RoutesConstants.AgentDashboard} element={<AgentRoute component={EventList} />} />
        <Route path={`${RoutesConstants.EventSummary}/:id`} element={<AgentRoute component={EventSummary} />} />
        {/* END OF AGENT ROUTES */}

        {/* TRAINER ROUTES */}
        <Route path={RoutesConstants.TrainerDashboard} element={<LayoutRoute component={TrainerDashboard} />} />
        <Route path={RoutesConstants.ManageGroups} element={<LayoutRoute component={ManageGroups} />} />
        <Route path={RoutesConstants.TrainerRecharge} element={<LayoutRoute component={RechargePlan} />} />
        {/* END OF TRAINER ROUTES */}

        {/* Payment Page */}
        <Route path={RoutesConstants.TestPayment} element={<LayoutRoute component={TestPayment} />} />
        <Route path={RoutesConstants.TrainerAccountPayment} element={<LayoutRoute component={TrainerDashboardPayment} />} />
        <Route path={`${RoutesConstants.TrainerRechargePayment}/:rechargeType`} element={<LayoutRoute component={TrainerRechargePayment} />} />
        {/* End of Payment Page */}

        <Route path={RoutesConstants.Stats} element={<LayoutRoute component={StatList} />} />
        <Route path={RoutesConstants.Life} element={<StaticLayoutRoute component={TestLayout} TestType={TestType.LIFE} />} />
        <Route path={RoutesConstants.Racing} element={<StaticLayoutRoute component={TestLayout} TestType={TestType.RACING}/>} />
        <Route path={RoutesConstants.Training} element={<StaticLayoutRoute component={TestLayout} TestType={TestType.TRAINING}/>} />
        <Route path={`${RoutesConstants.Life}/:token`} element={<StaticLayoutRoute component={TestLayout} TestType={TestType.LIFE} />} />
        <Route path={`${RoutesConstants.Racing}/:token`} element={<StaticLayoutRoute component={TestLayout} TestType={TestType.RACING}/>} />
        <Route path={`${RoutesConstants.Training}/:token`} element={<StaticLayoutRoute component={TestLayout} TestType={TestType.TRAINING}/>} />
        <Route path={`${RoutesConstants.Job}/:token`} element={<StaticLayoutRoute component={TestLayout} TestType={TestType.JOB}/>} />
        <Route path={`${RoutesConstants.TestResult}/:singleTestId`} element={<LayoutRoute component={TestResult} />} />
        <Route path={`${RoutesConstants.TestResultCompare}/:multipleTestIds`} element={<LayoutRoute component={TestResult} />} />
        {/* <Route path={`${RoutesConstants.DummyTestResult}`} element={<StaticLayoutRoute component={TestResult} />} /> */}

        <Route path={`${RoutesConstants.DemoTestResult}`} element={<StaticLayoutRoute component={TestResult} />} />
        <Route path={`${RoutesConstants.TestSubmission}`} element = {<StaticLayoutRoute component = {TestSubmission}></StaticLayoutRoute>}/>
        <Route path={RoutesConstants.ForgotPassword} element={<LoginLayoutRoute component={ForgotPassword}/>} />
        <Route path={`${RoutesConstants.ResetPassword}/:token`} element={<LoginLayoutRoute component={ResetPassword}/>} />
        <Route path="*" element={<StaticLayoutRoute component={PageNotFound} />} />
        
        <Route path={RoutesConstants.PaymentConfirmation} element={<LayoutRoute component={PaymentConfirmation}/>} />
      </Routes>
    </React.Fragment>
  );
};

export default RoutesComponent;
